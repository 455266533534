import React from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import DocsContext from '../../../contexts/docs';

import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import Footer from '../Footer';
import CurrentActiveItem from '../DocsBeta/CurrentActiveItem';

import styles from './Page.module.scss';
import globalStyles from '../DocsBeta/index.module.scss';
import BugFiler from '../BugFiler';
import PopUpBanner from '../PopUpBanner';
import Button from '../Button';

const Page = (props) => {
  const { showSearch, metadata, dispatch } = React.useContext(DocsContext);
  const scrollContainer = React.useRef(null);
  const { asPath } = useRouter();

  React.useEffect(() => {
    // if page is a deeplink, do nothing
    if (asPath.includes('#')) {
      return;
    }
    // if not, reset the scroll container so that
    // we dont end up somewhere down the page when the route changes
    if (scrollContainer?.current?.scrollTop != null) {
      scrollContainer.current.scrollTop = 0;
      scrollContainer.current.focus();
    }
  }, [asPath]);

  return (
    <>
      <div
        className={cx(
          globalStyles.docs,
          styles.layout,
          showSearch && styles.frozen,
        )}
      >
        <Sidebar className={styles.sidebar} />
        <div className={styles.navbarContainer}>
          <div
            className={cx(
              'docs-scroll-container',
              styles.contentScrollContainer,
            )}
            ref={scrollContainer}
            tabIndex={-1}
          >
            <>
              <Navbar />
            </>
            <CurrentActiveItem scrollContainer={scrollContainer.current} />
            <main className={styles.content} data-algolia='pageContent'>
              {props.children}
              <Footer />
            </main>
            {false && (
              <PopUpBanner id='survey_dec2024'>
                <div>
                  <b>Hi, Plaid developers!</b>
                </div>
                <div>
                  The Winter Plaid Developer Survey is here, and we'd love to
                  get your thoughts. It's just a few questions, and you'll be
                  done in 3 minutes, tops.
                </div>
                <a href='https://forms.gle/2CpNMDghrtwLyHUh8' target='_blank'>
                  <Button size={40} inline>
                    Take the survey!
                  </Button>
                </a>
              </PopUpBanner>
            )}
            <BugFiler />
          </div>
        </div>
      </div>
    </>
  );
};

Page.displayName = 'Page';
export default Page;
